import { formatISODateTime } from "../formatters";
import { arrayFrom, boolFrom, stringFrom } from "../parsing";
import { formatISODateTimeToTimeStamp } from "../formatters";
export function formatContacts(contact) {
  return {
    id: stringFrom(contact.id),
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(contact.created_on)
    ),
    createdOn: formatISODateTime(stringFrom(contact.created_on)),
    name: stringFrom(contact.contact_details.name),
    phone: stringFrom(contact.contact_details.phone),
    email: stringFrom(contact.contact_details.email),
    designation: stringFrom(contact.designation),
    userId: stringFrom(contact.fk_user),
    authGroups: arrayFrom(contact.user_details.groups),
    isSuperUser: boolFrom(contact.user_details.is_superuser),
    isAdmin: boolFrom(contact.is_admin),
  };
}
export function formatVendor(vendor) {
  var vendorData = vendor.vendor_details;
  var isAddressDetails = vendorData.address_details
    ? vendorData.address_details
    : {};

  return {
    id: stringFrom(vendor.id),
    orgVendorId: stringFrom(vendor.fk_organization_vendor),
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(vendor.created_on)
    ),
    createdOn: formatISODateTime(stringFrom(vendor.created_on)),
    displayName: stringFrom(vendorData.display_name),
    name: stringFrom(vendorData.display_name),
    pan: stringFrom(vendorData.pan),
    gst: stringFrom(vendorData.gst),
    legalName: stringFrom(vendorData.legal_name),
    ifsc: stringFrom(vendorData.ifsc),
    addressId: stringFrom(isAddressDetails.id),
    addressLine1: stringFrom(isAddressDetails.address_line_1),
    addressLine2: stringFrom(isAddressDetails.address_line_2),
    state: stringFrom(isAddressDetails.state),
    city: stringFrom(isAddressDetails.city),
    pinCode: stringFrom(isAddressDetails.pincode),
    location: stringFrom(isAddressDetails.location),
    beneficiaryName: stringFrom(vendorData.beneficiary_name),
    accountNumber: stringFrom(vendorData.account_number),
    contacts: arrayFrom(
      vendorData.contact_details.map((item) => {
        const { id, name, email, phone } = item.contact_details;
        return {
          id,
          name,
          email,
          phone,
          fkContact: item.fk_contact,
          isAlreadyPresent: 1,
        };
      })
    ),
  };
}
