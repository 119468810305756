<template>
  <v-toolbar density="compact" height="60" :color="color" class="bg-secondary"
    ><v-toolbar-title class="text-body-2 semibold"> {{ title }}</v-toolbar-title
    ><v-spacer />
    <v-btn
      class="mr-2"
      @click="$emit('action')"
      size="x-small"
      icon
      v-if="action"
      ><v-icon>{{ action }}</v-icon></v-btn
    >
    <v-btn
      class="mr-2"
      @click="$emit('close')"
      size="x-small"
      icon
      v-if="showClose"
      ><v-icon>{{ customCloseButton }}</v-icon></v-btn
    ></v-toolbar
  >
</template>

<script>
export default {
  name: "dialogHeading",
  data: () => ({}),
  props: {
    showClose: { type: Boolean, default: true },
    action: { type: String, default: "" },
    title: { type: String, default: "" },
    color: { type: String, default: "" },
    customCloseButton: { type: String, default: "mdi-close" },
  },

  methods: {},
};
</script>
