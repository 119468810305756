<template>
  <v-divider />
  <v-card-actions class="justify-center"
    ><v-spacer />
    <v-btn
      v-if="cancelButton"
      :disabled="loading"
      class="formCancelButton mr-2"
      variant="outlined"
      @click="$emit(cancelButtonAction)"
      >{{ cancelButtonText }}</v-btn
    >
    <v-btn
      :disabled="disabled"
      :loading="loading"
      class="formButton mr-2"
      variant="outlined"
      @click="$emit('clicked')"
      >{{ text }}</v-btn
    >
  </v-card-actions>
</template>

<script>
export default {
  emits: ["clicked", "cancel", "skip"],
  name: "FormActions",
  data: () => ({}),
  props: {
    text: { type: String, default: "Submit" },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    cancelButton: { type: Boolean, default: false },
    cancelButtonText: { type: String, default: "Cancel" },
    cancelButtonAction: { type: String, default: "cancel" },
  },

  methods: {},
};
</script>
