import { formatISODateTime, formatISODateTimeToTimeStamp } from "../formatters";
import { arrayFrom, stringFrom } from "../parsing";

export function formatAdditionalCharges(additionalCharges) {
  return {
    orders: additionalCharges
      .filter((item) => item.context_type === "orders")
      .map((item) => {
        return {
          id: stringFrom(item.id),
          name: stringFrom(item.header_name),
        };
      }),
    quotes: additionalCharges
      .filter((item) => item.context_type === "quotes")
      .map((item) => {
        return {
          id: stringFrom(item.id),
          name: stringFrom(item.header_name),
        };
      }),
  };
}

export function formatComments(comment) {
  return {
    id: stringFrom(comment.id),
    text: stringFrom(comment.text),
    createdBy: comment.contact_details?.name
      ? stringFrom(comment.contact_details.name)
      : "",
    createdOn: formatISODateTime(stringFrom(comment.created_at), "dateTime"),
    assets: arrayFrom(comment.asset_details).map((item) => ({
      id: comment.fk_asset,
      assetData: {
        id: item.id,
        src: item.url,
        name: item.name,
        type: item.type,
        description: item.description,
      },
    })),
  };
}

export function formatLogsData(logs) {
  const projects = {};
  const contexts = {};
  const modifiers = {};
  const logList = logs.map((log) => {
    const context = stringFrom(log.context_type);
    const formattedContext =
      context == "tasks"
        ? "Task"
        : context == "project_manpower"
        ? "Project Manpower"
        : context == "activity_status"
        ? "Activity Status"
        : context == "notes"
        ? "Notes"
        : context;
    const projectId = stringFrom(log.project_details.id);
    const projectName = stringFrom(log.project_details.name);
    const modifierId = stringFrom(log.contact_details.id);
    const modifierName = stringFrom(log.contact_details.name);
    if (!projects[projectId]) {
      projects[projectId] = projectName;
    }
    if (!contexts[context]) {
      contexts[context] = formattedContext;
    }
    if (!modifiers[modifierId]) {
      modifiers[modifierId] = modifierName;
    }
    return {
      id: stringFrom(log.id),
      logDateTime: formatISODateTime(stringFrom(log.updated_at), "dateTime"),
      logTimestamp: formatISODateTimeToTimeStamp(stringFrom(log.updated_at)),
      //PROJECT
      projectId: projectId,
      projectName: projectName,
      //MODIFIER
      modifierId: modifierId,
      modifierName: modifierName,
      //CONTEXT
      context: context,
      formattedContext: formattedContext,
      tab:
        context == "project_manpower"
          ? "manpower"
          : context == "activity_status"
          ? "activities"
          : context,
    };
  });
  return {
    logs: logList,
    projects: Object.keys(projects).map((key) => ({
      id: key,
      name: projects[key],
    })),
    contexts: Object.keys(contexts).map((key) => ({
      id: key,
      name: contexts[key],
    })),
    users: Object.keys(modifiers).map((key) => ({
      id: key,
      name: modifiers[key],
    })),
  };
}
