import { formatISODateTime, formatISODateTimeToTimeStamp } from "../formatters";
import { boolFrom, intFrom, stringFrom } from "../parsing";
export function formatMoodboardGroups(item) {
  return {
    id: stringFrom(item.id),
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(item.created_on)),
    createdOn: formatISODateTime(stringFrom(item.created_on)),
    name: stringFrom(item.name),
    sortIndex: intFrom(item.sort_index),
    isActive: boolFrom(item.is_active),
    items: item.moodboard_item_details.map((itemData) => ({
      id: stringFrom(itemData.id),
      title: stringFrom(itemData.title, ""),
      isActive: boolFrom(itemData.is_active),
      description: stringFrom(itemData.description),
      sortIndex: intFrom(itemData.sort_index),
      assetData: {
        id: itemData.asset_details.id,
        src: itemData.asset_details.url,
        name: itemData.asset_details.name,
        type: itemData.asset_details.type,
      },
    })),
  };
}
