import {
  formatISODateTime,
  adjustDate,
  formatISODateTimeToTimeStamp,
} from "../formatters";
import { arrayFrom, stringFrom } from "../parsing";
import store from "@/store";
export function formatActivities(activity) {
  var activitiesData = arrayFrom(activity.project_activity_status_details);
  return {
    id: stringFrom(activity.id),
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(activity.created_on)
    ),
    createdOn: formatISODateTime(stringFrom(activity.created_on)),
    startDate: formatISODateTime(stringFrom(activity.start_date)),
    endDate: adjustDate(
      stringFrom(activity.start_date),
      stringFrom(activity.activity_days),
      "add"
    ),
    progress:
      activitiesData.length > 0
        ? Math.round(
            activitiesData.reduce((max, activity) => {
              return activity.current_status > max
                ? activity.current_status
                : max;
            }, 0) * 100
          )
        : 0,
    title: stringFrom(activity.activity_item_details.name),
    type: stringFrom(activity.activity_tag_details.name),
    description: stringFrom(activity.description),
    activities: activitiesData.map((activityItem) => ({
      createdOn: formatISODateTime(stringFrom(activityItem.created_on)),
      updateText: `Updated from ${Math.round(
        activityItem.last_status * 100
      )}% to ${Math.round(activityItem.current_status * 100)}%`,
      date: formatISODateTime(activityItem.created_on, "dateTime"),
      createdBy:
        store.getters.orgContacts.find(
          (item) => item.id === activityItem.added_by
        )?.name || activityItem.added_by,
      description: activityItem.remark,
      images: arrayFrom(activityItem.project_activity_status_assets).map(
        (item) => {
          const { asset_details } = item;
          return {
            id: asset_details.id,
            src: asset_details.url,
            name: asset_details.name,
            type: asset_details.type,
            description: asset_details.description,
          };
        }
      ),
    })),
  };
}

export function formatActivityTags(tag) {
  return {
    id: stringFrom(tag.id),
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(tag.created_on)),
    createdOn: formatISODateTime(stringFrom(tag.created_on)),
    name: stringFrom(tag.name),
  };
}

export function formatActivityMasterItems(item) {
  return {
    id: stringFrom(item.id),
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(item.created_on)),
    createdOn: formatISODateTime(stringFrom(item.created_on)),
    name: stringFrom(item.name),
    fkActivityTag: stringFrom(item.activity_tag_details.name),
    fkActivityTagId: stringFrom(item.activity_tag_details.id),
  };
}
