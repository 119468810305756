// Function to convert JSON object to encoded string
export function encodeJsonWithKey(jsonObj, key) {
  // Ensure the key is 10 characters long
  if (key.length !== 10) {
    throw new Error("Key must be exactly 10 characters long.");
  }

  // Convert JSON to string
  const jsonString = JSON.stringify(jsonObj);

  // Encode each character in the JSON string
  let encodedString = "";
  for (let i = 0; i < jsonString.length; i++) {
    const charCode = jsonString.charCodeAt(i);
    const keyCharCode = key.charCodeAt(i % key.length);
    encodedString += String.fromCharCode(charCode ^ keyCharCode);
  }

  // Convert encoded string to Base64 to make it URL-safe
  return btoa(encodedString);
}

// Function to decode the encoded string back to JSON
export function decodeJsonWithKey(encodedString, key) {
  // Ensure the key is 10 characters long
  if (key.length !== 10) {
    throw new Error("Key must be exactly 10 characters long.");
  }

  // Decode Base64 string
  const decodedBase64 = atob(encodedString);

  // Decode each character back to original string
  let decodedString = "";
  for (let i = 0; i < decodedBase64.length; i++) {
    const charCode = decodedBase64.charCodeAt(i);
    const keyCharCode = key.charCodeAt(i % key.length);
    decodedString += String.fromCharCode(charCode ^ keyCharCode);
  }

  // Parse the JSON string back to an object
  return JSON.parse(decodedString);
}
