import store from "@/store";
export function getNavItems() {
  const navItems = [
    { title: "Projects", icon: "mdi-floor-plan" },
    { title: "Clients", icon: "mdi-account-group-outline" },
    { title: "My Tasks", icon: "mdi-clipboard-list-outline" },
  ];
  if (
    store.getters.permissions.isAdmin ||
    store.getters.permissions.isSuperAdmin
  ) {
    navItems.push({
      title: "Attendance",
      icon: "mdi-account-check-outline",
    });
  }
  navItems.push(
    { title: "Activities", icon: "mdi-rhombus-split-outline" },
    { title: "Settings", icon: "mdi-cog-outline" }
  );
  if (
    store.getters.permissions.isAdmin ||
    store.getters.permissions.isSuperAdmin
  ) {
    navItems.push({
      title: "Logs",
      icon: "mdi-text-box-check-outline",
    });
  }
  return navItems;
}
