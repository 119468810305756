import { formatISODateTime, formatISODateTimeToTimeStamp } from "../formatters";
import { stringFrom } from "../parsing";
export function formatOrderData(item) {
  return {
    id: stringFrom(item.id),
    createdTimestamp: formatISODateTimeToTimeStamp(stringFrom(item.created_on)),
    createdOn: formatISODateTime(stringFrom(item.created_on)),
    gst: stringFrom(Math.round(item.gst * 100)),
    uom: stringFrom(item.uom),
    purchaseRate: stringFrom(item.purchase_rate),
    description: stringFrom(item.description),
    name: stringFrom(item.name),
    image: stringFrom(item.asset_details && item.asset_details.url),
    clientRate: stringFrom(item.client_rate),
    assetData:
      item.asset_details && item.asset_details.url
        ? {
            id: item.asset_details.id,
            src: item.asset_details.url,
            name: item.asset_details.name,
            type: item.asset_details.type,
          }
        : {},
  };
}
