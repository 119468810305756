<template>
  <v-app-bar app height="60" elevation="2">
    <v-img
      class="ml-5"
      :src="
        $vuetify.display.xs
          ? require('@/assets/logo.png')
          : 'https://www.projectstudio.ai/wp-content/uploads/2024/09/logo.png'
      "
      alt="Project Studio"
      cover
      :style="
        $vuetify.display.xs
          ? 'max-width: 8% !important'
          : $vuetify.display.sm
          ? 'max-width: 25% !important'
          : $vuetify.display.md
          ? 'max-width: 20% !important'
          : $vuetify.display.lg
          ? 'max-width: 12% !important'
          : 'max-width: 10% !important'
      "
    />

    <v-spacer></v-spacer>
    <appBarMenu> </appBarMenu>
  </v-app-bar>
</template>

<script>
import appBarMenu from "./appBarMenu.vue";

export default {
  name: "AppBar",
  components: { appBarMenu },
  data() {
    return {};
  },
};
</script>
