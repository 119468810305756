import { createVuetify } from "vuetify";

export default createVuetify({
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#FFFFFF",
          secondary: "#F5F5F5",
          accent: "#0269DD",
          info: "#0099CC",
          warning: "#FF8800",
          success: "#4CAF50",
          error: "#F44336",
          background: "#FAFBFC",
        },
      },
    },
  },
});
