export const routes = [
  {
    path: "/homepage",
    beforeEnter() {
      location.href = "https://www.projectstudio.ai/";
    },
  },
  {
    path: "/",
    name: "Login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/projects",
    name: "Projects",
    // Adjust based on your authentication logic
    component: () => import("../views/projects.vue"),
  },
  {
    path: "/clients",
    name: "Clients",
    component: () => import("../views/clients.vue"),
  },
  {
    path: "/tasks",
    name: "My Tasks",
    component: () => import("../views/tasks.vue"),
  },
  {
    path: "/attendance",
    name: "Attendance",
    component: () => import("../views/attendance.vue"),
  },
  {
    path: "/activities",
    name: "Activities",
    component: () => import("../views/activities.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/settings.vue"),
  },
  {
    path: "/project",
    name: "Project Details",
    component: () => import("../views/projectDetails.vue"),
  },
  {
    path: "/logs",
    name: "Logs",
    component: () => import("../views/logs.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page Not Found",

    component: () => import("../views/notFoundPage.vue"),
  },
];
